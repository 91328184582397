import React, { useEffect, useState } from 'react';
import {
  BarChartOutlined,
  DotChartOutlined,
  LineChartOutlined,
  PieChartOutlined,
} from '@ant-design/icons';
import { Button, Flex, Radio, Typography } from 'antd';

export const JumpSelector = ({proyecto, index}) => {
    const [elementosLista, setElementosLista] = useState(proyecto.escenas[index].elementos.filter(elemento => elemento.tipo === 2));
    const [options, setOptions] = useState([]);

    useEffect(() => {    
      
      setElementosLista(proyecto.escenas[index].elementos.filter(elemento => elemento.tipo === 2))
      console.log("%c 🚀: elementos en effec de jump ", "color: orange; font-size: 16px", elementosLista)
      if (elementosLista.length > 0) {
        const newOptions = elementosLista.map((elemento, ind) => {
          return {
            value: elemento.escena,
            label: (
             (ind % 2 === 0)?
              <Flex gap="small" justify="center" align="center" vertical>
                <LineChartOutlined
                  style={{
                    fontSize: 18,
                  }}
                />
               { elemento.nombre + ' ' + elemento.escena}
              </Flex>: 
              <Flex gap="small" justify="center" align="center" vertical>
              <DotChartOutlined
                style={{
                  fontSize: 18,
                }}
              />
              {elemento.nombre + ' ' + elemento.escena}
            </Flex>
            )
          }
      })
      setOptions(newOptions)
    }
      
    }, [index])
    
    
  const [value, setValue] = useState(1);
  const onChange = (e) => {
    setValue(e.target.value);
  };
  
  return (
   <>
    {elementosLista !== null && elementosLista !== undefined && elementosLista.length > 0 ? (
      <Radio.Group
      onChange={onChange}
      value={value}
      options={ options}
 />   
         ):(
            <Typography.Text type="warning">No hay elementos para mostrar</Typography.Text>
    )}

<Button style={{marginTop:'20px'}} ghost type='primary' onClick={() => console.log('guardar jump')}>
      Guardar
          </Button>
    
    </> 
  );
}




// [
//   {
//     value: 1,
//     label: (
//       <Flex gap="small" justify="center" align="center" vertical>
//         <LineChartOutlined
//           style={{
//             fontSize: 18,
//           }}
//         />
//         LineChart
//       </Flex>
//     ),
//   },
//   {
//     value: 2,
//     label: (
//       <Flex gap="small" justify="center" align="center" vertical>
//         <DotChartOutlined
//           style={{
//             fontSize: 18,
//           }}
//         />
//         DotChart
//       </Flex>
//     ),
//   },
//   {
//     value: 3,
//     label: (
//       <Flex gap="small" justify="center" align="center" vertical>
//         <BarChartOutlined
//           style={{
//             fontSize: 18,
//           }}
//         />
//         BarChart
//       </Flex>
//     ),
//   },
//   {
//     value: 4,
//     label: (
//       <Flex gap="small" justify="center" align="center" vertical>
//         <PieChartOutlined
//           style={{
//             fontSize: 18,
//           }}
//         />
//         PieChart
//       </Flex>
//     ),
//   },
